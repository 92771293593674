<template>
  <div class="views-notFound">
    <el-row class="container">
      <el-row class="left">
        <el-image :src="pic"></el-image>
        <p>很抱歉！没有找到您要访问的页面！</p>
        <el-row class="tip">{{secound}} 秒后将自动跳转到首页</el-row>
        <el-row class="btns">
          <el-button @click="goHome">返回首页</el-button>
          <el-button @click="goPrev">上一步</el-button>
        </el-row>
      </el-row>
      <el-row class="right"></el-row>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "notFound",
  data() {
    return {
      pic: require("@/assets/images/404.png"),
      secound: 5,
    };
  },

  mounted() {
    let timer = setInterval(() => {
      if (this.secound <= 0) {
        this.goHome();
        return false;
      }
      this.secound--;
    }, 1000);
    this.$once("hook:destroyed", () => {
      clearInterval(timer);
    });
  },
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
    goPrev() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.views-notFound {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    .left {
      white-space: nowrap;
      p {
        margin: 30px 0 15px 0;
        font-size: 18px;
        color: #206231;
      }
      .tip {
        font-size: 14px;
        color: #999;
      }
      .btns {
        margin-top: 40px;
        font-size: 0;
        .el-button {
          display: inline-block;
          padding: 10px 40px;
          border: 1px solid #206231;
          font-size: 15px;
          color: #206231;
          border-radius: 0;
          &:first-child {
            margin-right: 20px;
            color: #fff;
            background-color: #206231;
          }
        }
      }
    }
    .right {
      width: 350px;
      height: 300px;
      margin-left: 100px;
      background: url("../assets/images/404bg.png") 0 0 no-repeat;
    }
  }
}
</style>